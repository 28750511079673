var yobou = (function(){
  var viewMode = '';

  function _init() {
    if( $(window).width() <= 750 ) {
      viewMode = 'SP';
    } else {
      viewMode = 'PC';
    }

    showModal();
    toggleContents();
    toggleHospital();
  }

  function showModal() {
    $('.js-modal-toggle, .js-modal').on('click', function() {
      $('.js-modal').fadeToggle(400);
    });

    $('.modal-inner').on('click', function(e) {
      e.stopPropagation();
    });
  }

  function toggleContents() {
    var $btn = $('.js-contents-toggle');
    var $btnSP = $('.js-contents-toggle-sp');
    var $contents = $('.js-contents');

    if(viewMode == 'SP') {
      $contents.css('display', 'none');
    }

    $btn.on('mouseover', function() {
      if(viewMode == 'PC') {
        var index = $btn.index($(this));

        $btn.removeClass('is-active');
        $btn.eq(index).addClass('is-active');

        $contents.css('display', 'none');
        $contents.eq(index).css('display', 'block');
      }
    });

    $btnSP.on('click', function() {
      if(viewMode == 'SP') {
        var index = $btnSP.index($(this));

        $(this).toggleClass('is-active');
        $contents.eq(index).slideToggle(300);
      }
    });
  }

  function toggleHospital() {
    $link = $('.hospital-item-lead').not('.is-show');

    $link.on('click', function() {
      if(viewMode == 'SP') {
        $(this).toggleClass('is-active');
        $(this).next('.hospital-item-text').slideToggle(300);
      }
    });
  }

  return {
    init : _init
  };

}());


$(window).on('load', yobou.init());